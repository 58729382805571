import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import moment from "moment";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";

const isYYYYMMDD = (dateString) => {
  if (dateString instanceof Date) {
    return false;
  } else {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    return dateString?.match(regEx) != null || false;
  }
};
const formatYYYYMMDD = (date) => {
  return isYYYYMMDD(date) ? date : moment(new Date(date)).format("YYYY-MM-DD");
};
const getDayFromDateString = (dateString) => {
  return dateString.substring(8, 10);
};
function toYYYYMMDD(year, month, date) {
  function twoDigit(n) {
    return (n < 10 ? "0" : "") + n;
  }

  return "" + year + "-" + twoDigit(month + 1) + "-" + twoDigit(date);
}

// Generates all days that show in a calendar grid for a particular year/month
// We just store YYYY-MM-DD as the ground truth here since the calendar has no
// knowledge of timezones
class MonthInformation {
  constructor(year, oneBasedMonth) {
    // month given to Date() starts at 0 = January
    const month = oneBasedMonth - 1;

    this.startDate = new Date(year, month, 1);
    // 0 `day` gets last day from prior month
    this.endDate = new Date(year, month + 1, 0);

    // result of getDay(): 0 means Sunday and 6 means Saturday
    this.startDay = this.startDate.getDay();
    // last day number = total days in current month
    this.currentMonthTotalDays = this.endDate.getDate();
    this.totalWeeks = Math.ceil(
      (this.currentMonthTotalDays + this.startDay) / 7,
    );

    const prevMonthEndDate = new Date(year, month, 0);
    let prevMonthDay = prevMonthEndDate.getDate() - this.startDay + 1;
    let nextMonthDay = 1;
    this.dates = [];

    for (let i = 0; i < this.totalWeeks * 7; i += 1) {
      let date;
      let isCurrentMonth;
      // Previous month dates (if month does not start on Sunday)
      if (i < this.startDay) {
        date = toYYYYMMDD(year, month - 1, prevMonthDay);
        prevMonthDay = prevMonthDay + 1;
        isCurrentMonth = false;
        // Next month dates (if month does not end on Saturday)
      } else if (i > this.currentMonthTotalDays + (this.startDay - 1)) {
        date = toYYYYMMDD(year, month + 1, nextMonthDay);
        nextMonthDay = nextMonthDay + 1;
        isCurrentMonth = false;
        // Current month dates.
      } else {
        date = toYYYYMMDD(year, month, i - this.startDay + 1);
        isCurrentMonth = true;
      }
      this.dates.push({
        date: date,
        isCurrentMonth: isCurrentMonth,
      });
    }
    return this;
  }
}

function isSameDay(d1, d2) {
  return d1 === d2;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DatePicker({ options, value, onChange, dark = false }) {
  const today = "2023-07-01";
  const [localShowCalendar, setLocalShowCalendar] = useState(false);
  const [currentDate, setCurrentDate] = useState(() => {
    const startDate = value ? new Date(value) : new Date(today);
    return { year: startDate.getFullYear(), month: startDate.getMonth() };
  });

  // The two below should be consistent so we can always find the value in the options
  // We need to support two modes of inputs: value and options are in YYYY-MM-DD string,
  // or they are both dates that that need to be converted to the string format to match
  // what's in the MonthInformation
  const valueFormatted = formatYYYYMMDD(value);

  const dateOptions = new Map(options.map((s) => [formatYYYYMMDD(s), s]));

  const updateCurrentDate = (monthOffset) => {
    setCurrentDate(({ year, month }) => {
      month = month + monthOffset;
      if (month === 12) {
        month = 0;
        year = year + 1;
      } else if (month === -1) {
        month = 12;
        year = year - 1;
      }
      return { year, month };
    });
  };

  const dateInfo = new MonthInformation(
    currentDate.year,
    currentDate.month + 1,
  );
  dateInfo.dates.forEach((d) => {
    d.isAvailable = dateOptions.has(d.date);
    d.isSelected = isSameDay(d.date, valueFormatted);
    d.isToday = isSameDay(d.date, today);
  });

  const selectPreviousOption = () => {
    const ind = options.findIndex((d) => d === value);
    // Since the date is sorted in reverse, the previous option actually is the next in the array
    ind < options.length - 1 && onChange(options[ind + 1]);
    const selectedDate = new Date(options[ind + 1]);
    if (selectedDate.getMonth() < currentDate.month) {
      setCurrentDate({
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
      });
    }
  };
  const SelectNextOption = () => {
    const ind = options.findIndex((d) => d === value);
    ind > 0 && onChange(options[ind - 1]);
    const selectedDate = new Date(options[ind - 1]);

    if (selectedDate.getMonth() > currentDate.month) {
      setCurrentDate({
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
      });
    }
  };

  const toggleShowCalendar = () => {
    setLocalShowCalendar(!localShowCalendar);
  };
  return (
    <div>
      <div className="mt-2 text-center">
        <Popover className="relative">
          {({ open }) => (
            <>
              <div className="flex justify-center space-x-3">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    onClick={() => selectPreviousOption()}
                  >
                    <span className="sr-only">Previous available date</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <label
                    className={classNames(
                      "flex-auto text-sm font-semibold ",
                      dark ? "text-gray-700" : "text-gray-100",
                    )}
                  >
                    {value ? moment(value).format("YYYY-MM-DD") : "Loading..."}
                  </label>
                  <div className="flex">
                    <button
                      type="button"
                      className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                      onClick={() => SelectNextOption()}
                    >
                      <span className="sr-only">Next available date</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    className="my-auto mr-2"
                    onClick={() => toggleShowCalendar()}
                  >
                    <CalendarIcon
                      className={`${
                        localShowCalendar ? "text-gray-600" : " text-gray-400"
                      } h-5 w-5 hover:text-gray-500`}
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
              <Transition
                as={Fragment}
                show={localShowCalendar}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel static className="absolute z-10 mt-3">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-2 bg-gray-900">
                      <div className="mt-2 flex items-center text-gray-900">
                        <button
                          type="button"
                          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                          onClick={() => updateCurrentDate(-1)}
                        >
                          <span className="sr-only">Previous month</span>
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                        <div className="flex-auto text-sm text-gray-300 font-semibold">
                          {dateInfo.startDate.toLocaleString("default", {
                            month: "long",
                            year: "numeric",
                          })}
                        </div>
                        <button
                          type="button"
                          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                          onClick={() => updateCurrentDate(+1)}
                        >
                          <span className="sr-only">Next month</span>
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <div className="mt-2 px-2 grid grid-cols-7 text-xs leading-6 text-gray-300">
                        <div>S</div>
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>S</div>
                      </div>
                      <div className="isolate my-2 mx-2 grid grid-cols-7 gap-px rounded-lg bg-gray-300 text-sm shadow ring-1 ring-gray-200">
                        {dateInfo.dates.map((day, dayIdx) => (
                          <button
                            key={day.date}
                            onClick={() => {
                              const date = dateOptions.get(day.date);
                              if (date) {
                                onChange(date);
                              }
                            }}
                            type="button"
                            className={classNames(
                              "py-1.5 focus:z-10",
                              day.isCurrentMonth && day.isAvailable
                                ? "bg-white hover:bg-gray-50 "
                                : "bg-gray-200 cursor-default",
                              (day.isSelected || day.isToday) &&
                                "font-semibold",
                              day.isSelected && "text-white",
                              !day.isSelected &&
                                day.isCurrentMonth &&
                                !day.isToday &&
                                "text-gray-900",
                              !day.isSelected &&
                                !day.isCurrentMonth &&
                                !day.isToday &&
                                "text-gray-400",
                              day.isToday && !day.isSelected && "text-cyan-600",
                              dayIdx === 0 && "rounded-tl-lg",
                              dayIdx === 6 && "rounded-tr-lg",
                              dayIdx === dateInfo.dates.length - 7 &&
                                "rounded-bl-lg",
                              dayIdx === dateInfo.dates.length - 1 &&
                                "rounded-br-lg",
                            )}
                          >
                            <time
                              dateTime={day.date}
                              className={classNames(
                                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                                day.isSelected && day.isToday && "bg-cyan-600",
                                day.isSelected && !day.isToday && "bg-gray-900",
                              )}
                            >
                              {getDayFromDateString(day.date)}
                            </time>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}
