import { fetchAuthSession } from "aws-amplify/auth";
import { useLayoutEffect, useState } from "react";
import moment from "moment";
import { STAC_STAGE } from "./config/constants.js";

function concat(type, arrays) {
  let i = 0;
  let n = 0;
  for (const a of arrays) n += a.length;
  const concat = new type(n);
  for (let a of arrays) {
    concat.set(a, i);
    i += a.length;
  }
  return concat;
}

async function stream(url, setProgress = console.log) {
  const r = await fetch(url, {
    method: "HEAD",
  });
  const size = r.headers.get("content-length");

  const response = await fetch(url);

  // Check if streaming is supported.
  const reader = response.body?.getReader();
  if (!reader) {
    const value = new Uint8Array(await response.arrayBuffer());
    setProgress(1);
    return value;
  }

  // Read chunks, updating as we go.
  const values = [];
  let progress = 0;
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    progress += value.length;
    if (size) {
      setProgress(progress / size);
    }
    values.push(value);
  }
  setProgress(1);

  // Concatenate chunks into an array.
  return concat(Uint8Array, values);
}

export async function jsonWithProgress(url, setProgress) {
  return stream(url, setProgress).then((s) => {
    const s_decoded = new TextDecoder("utf-8").decode(s);
    let json = JSON.parse(s_decoded);
    return json;
  });
}

export function fetchGroups(setGroups) {
  const fetchUrl = async (url) => {
    const { accessToken } = (await fetchAuthSession()).tokens;
    const groups = accessToken.payload["cognito:groups"];
    return groups;
  };
  fetchUrl().then((out) => setGroups(out));
}
export const fetchUrl = (url) => {
  const fetchData = async (url) => {
    const idToken = (await fetchAuthSession()).tokens?.idToken;
    const headers = {
      Authorization: idToken.toString(),
    };
    const response = await fetch(url, { headers: headers });
    const data = await response.json();
    return data;
  };

  return fetchData(url)
    .then((out) => {
      let rangeData = [];
      for (const item of out?.items || []) {
        rangeData.push({
          date: item.date,
          seed_date: item.seed_date,
          region: item.region,
          key: item.key,
          data: JSON.parse(item.data),
        });
      }
      return rangeData;
    })
    .catch((err) => {
      throw err;
    });
};

export function format(value, precision) {
  return (+value.toFixed(precision)).toLocaleString();
}

// From https://stackoverflow.com/a/19014495
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function getCountryBoundary(country, setProgress) {
  const stacUrl =
    "https://stac-server-" +
    STAC_STAGE +
    ".groundtruthanalytics.com/collections/country_shapefiles/items/" +
    country;
  return jsonWithProgress(stacUrl, setProgress);
}

export function getDaysArray(start, end) {
  // From end to start to be consistent with the pheno options
  for (
    var arr = [], dt = new Date(end);
    dt >= new Date(start);
    dt.setDate(dt.getDate() - 1)
  ) {
    arr.push(moment(new Date(dt)).format("YYYY-MM-DD"));
  }
  return arr;
}

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

const rgbaToHex = (r, g, b, a) => {
  return (
    "#" +
    componentToHex(r) +
    componentToHex(g) +
    componentToHex(b) +
    componentToHex(a)
  );
};
export const makeColorMap = (colorMap, n, opacity) => {
  const colorMapLength = Object.keys(colorMap).length;
  return [...Array(n)].map((_, i) => {
    const index = Math.floor((i * (colorMapLength - 1)) / (n - 1));
    const [r, g, b, a] = colorMap[index];
    return rgbaToHex(r, g, b, a * opacity);
  });
};
export const makeRange = (start, end, n) => {
  let range = [];
  let i = start;
  let step = (end - start) / (n - 1);
  for (let counter = 0; counter < n; counter++) {
    range.push(i);
    i += step;
  }
  return range;
};
