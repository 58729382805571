import Dashboard from "./pages/Dashboard";
import Empty from "./pages/Empty";
import SignIn from "./pages/SignIn";
import Settings from "./pages/Settings";

const routes = [
  {
    path: "/",
    main: () => <Dashboard />,
    title: () => "Dashboard",
  },
  {
    path: "/settings",
    main: () => <Settings />,
    title: () => "Settings",
  },
  {
    path: "/help",
    main: () => <Empty />,
    title: () => "Help",
  },
  {
    path: "/sign-in",
    main: () => <SignIn />,
    title: () => "",
  },
  {
    path: "*",
    main: () => <Empty />,
    title: () => "Not found",
  },
];

export default routes;
