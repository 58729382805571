import React, { useState, useEffect, useContext } from "react";

import Map from "../components/Map";
import Statistics from "../components/Statistics";
import Layout from "../components/Layout/Layout";
import moment from "moment";
import Slideover from "../components/Slideover";
import DatePicker from "../components/DatePicker";
import {
  API_STAGE,
  CROPS,
  PHENOLOGIES,
  CROPS_BY_COUNTRY,
  PHENOLOGIES_BY_COUNTRY,
  CROP_COLORS,
  SEQUENTIAL_COLORS,
} from "../config/constants.js";

import { UserContext } from "../App";
import { fetchUrl, getDaysArray } from "../utils.js";

export default function Dashboard() {
  const {
    country,
    regions,
    getRegionByLevel,
    getLastRegion,
    regionOptions,
    maxLevel,
    crop,
    statsDate,
    showFieldDelineation,
    activeSatelliteLayer,
    getRegionNameByLevel,
    crops,
    statsOpen,
    setStatsOpen,
  } = useContext(UserContext);
  const showPhenologicalStage = crop !== "All";

  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState("Land use");

  const toggleStats = () => {
    if (
      activeSatelliteLayer &&
      [
        "rainfall_monthly",
        "rainfall_daily",
        "temperature_day_daily",
        "evapotranspiration_8day",
      ].includes(activeSatelliteLayer.id)
    ) {
      setCurrentTab("Weather and Indices");
    } else if (showFieldDelineation) {
      showPhenologicalStage
        ? setCurrentTab("Phenological stage")
        : setCurrentTab("Crop type");
    }
    setStatsOpen(!statsOpen);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    setStartDate(moment(statsDate).subtract(10, "days").format("YYYY-MM-DD"));
    setEndDate(statsDate);
  }, [statsDate]);

  const weatherDateOptions = getDaysArray(
    "2023-01-01",
    moment().format("YYYY-MM-DD"),
  );
  let DateRangeToggle = () => {
    return (
      <div className="grid grid-cols-2">
        <div>
          <dt className="text-center text-sm font-medium text-gray-500">
            Start Date
          </dt>
          <DatePicker
            options={weatherDateOptions}
            value={startDate}
            onChange={setStartDate}
            dark={true}
          />
        </div>
        <div>
          <dt className="text-center text-sm font-medium text-gray-500">
            End Date
          </dt>
          <DatePicker
            options={weatherDateOptions}
            value={endDate}
            onChange={setEndDate}
            dark={true}
          />
        </div>
      </div>
    );
  };

  const [landuseData, setLanduseData] = useState({});
  const [phenoData, setPhenoData] = useState({});
  const [cropData, setCropData] = useState({});

  const [dataNDVI, setDataNDVI] = useState([]);
  const [dataTemperatureDay, setDataTemperatureDay] = useState([]);
  const [dataTemperatureNight, setDataTemperatureNight] = useState([]);
  const [dataPrecipitation, setDataPrecipitation] = useState([]);

  const apiUrl = (pkey, startDate, endDate, region) => {
    startDate = moment(startDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");
    if (region == undefined) {
      return `https://api-${API_STAGE}.groundtruthanalytics.com/regions/summary/?key=${pkey}&client=${country}&startDate=${startDate}&endDate=${endDate}`;
    } else {
      return `https://api-${API_STAGE}.groundtruthanalytics.com/regions/summary/?key=${pkey}&client=${country}&startDate=${startDate}&endDate=${endDate}&regionId=${region}`;
    }
  };

  const getRootNodeFromHierarchy = (root) => {
    let level = 0;
    while (root?.children) {
      let region_name = getRegionByLevel(level);
      let ind = root.children.findIndex(({ id }) => id === region_name);
      if (region_name === "All") {
        return [root, level];
      } else if (ind >= 0) {
        root = root.children[ind];
        level++;
      } else {
        throw new Error("Cannot get root node from hierarchy");
      }
    }
    return [root, level];
  };

  const lookup = (node, data, key) => {
    return data?.[node?.id]?.[key];
  };

  const generateBarData = (node) => {
    return {
      labels: lookup(node, landuseData, "histogram_bins") || 0,
      datasets: [
        {
          label: "Number of parcel",
          data: lookup(node, landuseData, "histogram_counts") || 0,
        },
      ],
    };
  };
  const generatePhenoBarData = (node) => {
    return {
      datasets: [
        {
          labels: "Pheno Type",
          data:
            PHENOLOGIES_BY_COUNTRY[country]?.map((pheno, i) => ({
              x: pheno,
              y:
                lookup(node, phenoData, PHENOLOGIES[pheno]["id"])?.["count"] ||
                0,
            })) || [],
          borderColor: "#0891b280",
          backgroundColor: SEQUENTIAL_COLORS.map((x) => `${x}80`), //"#0891b280",
        },
      ],
    };
  };
  const generateCropBarData = (node) => {
    return {
      datasets: [
        {
          labels: "Crop Type",
          data:
            CROPS_BY_COUNTRY[country]?.slice(1).map((crop, i) => ({
              x: crop === "grain" ? "cereals" : crop,
              y: lookup(node, cropData, CROPS[crop]["id"])?.["count"] || 0,
            })) || [],
          borderColor: "#0891b280",
          backgroundColor: CROP_COLORS?.map((x) => `${x}80`), //"#0891b280",
        },
      ],
    };
  };
  const generateNDVIBarData = (node) => {
    let uniqueDays = [];
    Object.values(dataNDVI).map((d) => {
      uniqueDays.push(...Object.keys(d));
    });
    uniqueDays = uniqueDays.filter((x, i, a) => a.indexOf(x) == i);
    uniqueDays.sort();
    return {
      datasets: [
        {
          labels: "NDVI",
          data:
            uniqueDays.map((day, i) => ({
              x: day,
              y: lookup(node, dataNDVI, day)?.["value"],
            })) || [],
          borderColor: "#0891b280",
          backgroundColor: "#0891b280",
        },
      ],
    };
  };

  // crop and pheno stats
  const generateDataForNode = (node, name, level, dataLookup) => {
    // use for crop and pheno with all keys as list
    let options = [];
    let all_options = [];
    if (dataLookup === cropData) {
      options = CROPS_BY_COUNTRY[country]?.slice(1);
      all_options = CROPS;
    } else {
      options = PHENOLOGIES_BY_COUNTRY[country];
      all_options = PHENOLOGIES;
    }
    return options?.map((val, i) => {
      let info = lookup(node, dataLookup, all_options[val]["id"]);
      return {
        key: `${level}_${name}`,
        values: [
          "",
          val === "grain" ? "cereals" : val,
          info?.["count"] || 0,
          ((info?.["area_sum"] || 0) / 10000).toFixed(2),
          ((info?.["area_median"] || 0) / 10000).toFixed(2),
        ],
      };
    });
  };

  // landuse stats
  const generateLandUseItem = (node, name, level, dataLookup) => {
    return {
      key: `${level}_${name}`,
      values: [
        `${level}_${name}`,
        lookup(node, dataLookup, "count") || 0,
        ((lookup(node, dataLookup, "area_sum") || 0) / 10000).toFixed(2),
        ((lookup(node, dataLookup, "area_median") || 0) / 10000).toFixed(2),
      ],
    };
  };
  const generateDataForTable = (node, region, level, dataLookup) => {
    let data = {};
    data.data = [
      dataLookup === landuseData
        ? generateLandUseItem(node, region, level, dataLookup)
        : {
            key: `${level}_${region}`,
            values: [`${level}_${region}`, "", "", "", ""],
          },
    ];
    data.details =
      dataLookup === landuseData
        ? []
        : generateDataForNode(node, region, level, dataLookup);

    data.children = [];
    if (node?.children) {
      Object.entries(node.children).forEach(([child, childNode]) => {
        data.children.push(
          generateDataForTable(
            childNode,
            childNode["name"],
            level + 1,
            dataLookup,
          ),
        );
      });
    }

    return data;
  };

  var [rootNode, level] = getRootNodeFromHierarchy(regionOptions);
  const dataForTable = generateDataForTable(
    rootNode,
    getRegionNameByLevel(regionOptions, level - 1),
    level,
    landuseData,
  );

  const bardata = generateBarData(rootNode);
  const totalArea = lookup(rootNode, landuseData, "area_sum") / 10_000 || 0;
  const parcelCount = lookup(rootNode, landuseData, "count") || 0;

  const bardataPheno = generatePhenoBarData(rootNode);
  const dataByPhenologyForTable = generateDataForTable(
    rootNode,
    getRegionNameByLevel(regionOptions, level - 1),
    level,
    phenoData,
  );

  const bardataCrop = generateCropBarData(rootNode);
  const dataByCropForTable = generateDataForTable(
    rootNode,
    getRegionNameByLevel(regionOptions, level - 1),
    level,
    cropData,
  );

  const convertData = (data) => {
    let dataOut = {};
    data.map((d) => {
      dataOut[d.region] = d.data[d.region];
    });
    return dataOut;
  };

  useEffect(() => {
    if (showFieldDelineation || (statsOpen && currentTab === "Land use")) {
      let key = crop === "All" ? "area" : "area_" + CROPS[crop]["id"];
      fetchUrl(apiUrl(key, statsDate, statsDate)).then((data) => {
        setLanduseData(convertData(data));
      });
    }
    if (statsOpen && currentTab === "Phenological stage") {
      fetchUrl(
        apiUrl("area_by_phenology_" + CROPS[crop]["id"], statsDate, statsDate),
      ).then((data) => {
        setPhenoData(convertData(data));
      });
    }

    if (statsOpen && currentTab === "Crop type") {
      fetchUrl(apiUrl("area_by_crop", statsDate, statsDate)).then((data) => {
        setCropData(convertData(data));
      });
    }
  }, [statsOpen, statsDate, currentTab, crop]);

  const groupByDate = (data) => {
    let m = {};
    let region = data[0]?.["region"];
    m[region] = {};
    data.map((d) => {
      m[region][d.date] = {
        value: d.data[region]?.value_sum / d.data[region]?.area_sum || 0,
      };
    });
    return m;
  };
  useEffect(() => {
    if (statsOpen && currentTab === "Weather and Indices") {
      let regionToRequest = getLastRegion();
      fetchUrl(
        apiUrl(
          crop === "All" ? "ndvi" : "ndvi_" + CROPS[crop]["id"],
          startDate,
          endDate,
          regionToRequest,
        ),
      ).then((data) => {
        let newData = groupByDate(data);
        setDataNDVI(newData);
      });

      fetchUrl(
        apiUrl(
          crop === "All"
            ? "temperature_day_daily"
            : "temperature_day_daily_" + CROPS[crop]["id"],
          startDate,
          endDate,
          regionToRequest,
        ),
      ).then((data) => {
        let newData = groupByDate(data);
        setDataTemperatureDay(newData);
      });

      fetchUrl(
        apiUrl(
          crop === "All"
            ? "temperature_night_daily"
            : "temperature_night_daily_" + CROPS[crop]["id"],
          startDate,
          endDate,
          regionToRequest,
        ),
      ).then((data) => {
        let newData = groupByDate(data);
        setDataTemperatureNight(newData);
      });

      fetchUrl(
        apiUrl(
          crop === "All"
            ? "rainfall_daily"
            : "rainfall_daily_" + CROPS[crop]["id"],
          startDate,
          endDate,
          regionToRequest,
        ),
      ).then((data) => {
        let newData = groupByDate(data);
        setDataPrecipitation(newData);
      });
    }
  }, [statsOpen, currentTab, startDate, endDate, crop, regions]);

  const generateWeatherBarData = (node, dataLookup) => {
    let uniqueDays = [];
    Object.values(dataLookup).map((d) => {
      uniqueDays.push(...Object.keys(d));
    });
    uniqueDays = uniqueDays.filter((x, i, a) => a.indexOf(x) == i);
    uniqueDays.sort();
    let data = uniqueDays.map((day, i) => {
      return (
        {
          scene_datetime_start: day,
          value: lookup(node, dataLookup, day)?.["value"],
        } || []
      );
    });
    return data;
  };

  let barTemperatureDay = generateWeatherBarData(rootNode, dataTemperatureDay);
  let barTemperatureNight = generateWeatherBarData(
    rootNode,
    dataTemperatureNight,
  );
  let barPrecipitation = generateWeatherBarData(rootNode, dataPrecipitation);
  let bardataNDVI = generateNDVIBarData(rootNode);

  let bardataWeather = {
    datasets: [
      {
        type: "bar",
        label: "Precipitation",
        data: barPrecipitation?.map((b) => {
          return {
            x: new Date(b.scene_datetime_start),
            y: b.value,
          };
        }),
        yAxisID: "y1",
        backgroundColor: "#00000066",
        barPercentage: 0.4,
      },
      {
        type: "line",
        label: "Day temperature",
        data: barTemperatureDay
          ?.filter((d) => {
            return !!d.value;
          })
          .map((b) => {
            return {
              x: new Date(b.scene_datetime_start),
              y: b.value * 0.02 - 273.15,
            };
          })
          .sort(function (first, second) {
            return second.x - first.x;
          }),
        borderColor: "#d9770680",
        backgroundColor: "#d9770680",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Night temperature",
        data: barTemperatureNight
          ?.filter((d) => {
            return !!d.value;
          })
          .map((b) => {
            return {
              x: new Date(b.scene_datetime_start),
              y: b.value * 0.02 - 273.15,
            };
          })
          .sort(function (first, second) {
            return second.x - first.x;
          }),
        borderColor: "#0891b280",
        backgroundColor: "#0891b280",
        yAxisID: "y",
      },
    ],
  };

  return (
    <Layout>
      <Map
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        toggleStats={toggleStats}
        landuseData={landuseData}
      ></Map>
      <Slideover
        maxLevel={maxLevel}
        statsOpen={statsOpen}
        setStatsOpen={setStatsOpen}
        country={country}
        regions={regions}
        crop={crop}
        getRegionNameByLevel={getRegionNameByLevel}
        regionOptions={regionOptions}
      >
        <Statistics
          country={country}
          crop={crop}
          statsDate={statsDate}
          statsOpen={statsOpen}
          regions={regions}
          getRegionByLevel={getRegionByLevel}
          crops={crops}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          showFieldDelineation={showFieldDelineation}
          showPhenologicalStage={showPhenologicalStage}
          data={data}
          dataByCropForTable={dataByCropForTable}
          dataByPhenologyForTable={dataByPhenologyForTable}
          dataForTable={dataForTable}
          bardata={bardata}
          bardataCrop={bardataCrop}
          bardataPheno={bardataPheno}
          bardataNDVI={bardataNDVI}
          bardataWeather={bardataWeather}
          totalArea={totalArea}
          parcelCount={parcelCount}
          DateRangeToggle={DateRangeToggle}
        />
      </Slideover>
    </Layout>
  );
}
