import { useState, useEffect, useContext } from "react";

import { PlusSmallIcon, MinusSmallIcon } from "@heroicons/react/24/solid";
import { UserContext } from "../App";

export default function MyTable({ title, info, header }) {
  const { crop } = useContext(UserContext);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function TableContent({ d, level }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const expand = () => {
      setIsExpanded(!isExpanded);
    };
    return (
      <>
        {d?.data?.map((ddata, ii) => {
          return (
            <tr key={ddata.key + "_" + ii}>
              {ddata.values.map((v, i) => (
                <td
                  key={i}
                  className={classNames(
                    i === 0
                      ? "font-medium text-gray-900 pl-" +
                          (4 * level + (d.children.length == 0 ? 2 : 0))
                      : "px-3",
                    "whitespace-nowrap py-2 text-xs text-gray-500",
                  )}
                >
                  {i == 0 ? (
                    <button
                      key={i}
                      className={"flex items-center"}
                      onClick={() => expand()}
                    >
                      {d.children.length > 0 && isExpanded && (
                        <MinusSmallIcon
                          className="mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      {d.children.length > 0 && !isExpanded && (
                        <PlusSmallIcon
                          className="mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      {v}
                    </button>
                  ) : (
                    <span key={i}>{v}</span>
                  )}
                </td>
              ))}
            </tr>
          );
        })}
        {!isExpanded &&
          d?.details?.map((ddata, ii) => {
            return (
              <tr key={ddata.key + "_" + ii}>
                {ddata.values.map((v, i) => (
                  <td
                    key={i}
                    className="px-3 whitespace-nowrap py-2 text-xs text-gray-500 bg-gray-100"
                  >
                    <span key={i}>{v}</span>
                  </td>
                ))}
              </tr>
            );
          })}
        {isExpanded &&
          d.children?.map((child, i) => {
            return <TableContent d={child} level={level + 1} key={i} />;
          })}
      </>
    );
  }

  return (
    <div className="py-4 w-96 max-w-full mx-auto">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h1>
        </div>
      </div>
      <div className="mt-4 px-2 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {header.map((h, i) => {
                    return (
                      <th
                        key={i}
                        scope="col"
                        className={classNames(
                          i === 0 ? "pl-4 pr-3 sm:pl-0" : "px-3 ",
                          "py-2 text-left text-xs font-semibold text-gray-900",
                        )}
                      >
                        {h}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <TableContent d={info} level={0} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
