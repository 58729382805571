import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify/utils";
import { createContext } from "react";
import { CogIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import "@aws-amplify/ui-react/styles/reset.layer.css"; // global CSS reset
import "@aws-amplify/ui-react/styles/base.layer.css"; // base styling needed for Amplify UI
import "@aws-amplify/ui-react/styles/button.layer.css"; // component specific styles
import routes from "./routes";
import { getCountryBoundary, fetchGroups } from "./utils.js";
import { components, formFields } from "./pages/SignIn.js";
import { CLIENTS } from "./config/constants.js";

const secondaryNavigation = [
  { name: "Settings", href: "/settings", icon: CogIcon },
  { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
];

export const UserContext = createContext("AppContext");

function GTA({ signOut }) {
  const [statsOpen, setStatsOpen] = useState(false);
  const navigate = useNavigate();

  let countryStored =
    localStorage.getItem("country") === null
      ? ""
      : localStorage.getItem("country");
  const [country, setCountry] = useState(countryStored);
  const [regions, setRegions] = useState(["All"]);
  const [groups, setGroups] = useState([]);

  const [crop, setCrop] = useState("All");
  const [statsDate, setStatsDate] = useState("");
  const [season, setSeason] = useState(2022);
  const [date, setDate] = useState("");

  const [showFieldDelineation, setShowFieldDelineation] = useState(true);
  const [downloadPoints, setDownloadPoints] = useState(false);
  const [downloadPolygons, setDownloadPolygons] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const [activeSatelliteLayer, setActiveSatelliteLayer] = useState(null);

  const [progress, setProgress] = useState(0);

  const [countryBoundary, setCountryBoundary] = useState({});
  const regionOptions = countryBoundary?.properties?.["gta:hierarchy"];

  useEffect(() => {
    getCountryBoundary(country, setProgress).then(setCountryBoundary);
    setStatsDate(CLIENTS[country]?.["statsDate"]);
  }, [country]);

  const [datesOptions, setDatesOptions] = useState([""]);
  const [datesOptionsPheno, setDatesOptionsPheno] = useState([""]);

  const [mapRef, setMapRef] = useState([]);

  const satelliteLayers = [
    {
      id: "sentinel2",
      texts: "True Color Image",
      region: "us-west-2",
      satellite: "sentinel2_l2a_cog",
      params: [
        ["bands", "B4"],
        ["bands", "B3"],
        ["bands", "B2"],
      ],
      colorMap: {
        min: 0,
        max: 3558,
      },
    },
    {
      id: "sentinel1",
      texts: "Radar Image",
      region: "eu-central-1",
      satellite: "sentinel1_rtc_azure",
      // Enhanced version from:
      // https://custom-scripts.sentinel-hub.com/custom-scripts/sentinel-1/sar_false_color_visualization/
      params: [
        [
          "expression",
          [
            "0.03 + log(10e-4 - log(0.05 / (0.02 + 2.5 * vv)) + log(0.05 / (0.02 + 1.5 * vh)))",
            "0.05 + exp(0.25 * (log(0.01 + 2 * vv) + log(0.02 + 7 * vh)))",
            "0.8 - log(0.05 / (0.045 - 0.9 * vv))",
          ].join(";"),
        ],
        ["rescale", "0,0.8"],
        ["rescale", "0,1"],
        ["rescale", "0,1"],
      ],
    },
    {
      id: "ndvi",
      texts: "Vegetation Index",
      region: "us-west-2",
      satellite: "sentinel2_l2a_cog",
      params: [["expression", "(B08 - B04) / (B08 + B04)"]],
      colorMap: {
        legendTitle: "Vegetation index (NDVI)",
        min: -1,
        max: 1,
        colorMapName: "RdYlGn",
      },
    },
    {
      id: "temperature_day_daily",
      texts: "Temperature",
      region: "eu-central-1",
      satellite: "modis11a1_myd",
      params: [
        ["bands", "LST_Day_1km"],
        ["unscale", "True"],
        ["resampling", "bilinear"],
        ["padding", 1],
      ],
      colorMap: {
        legendTitle: "Temperature (C)",
        min: 0,
        max: 50,
        offset: 273.15,
        colorMapName: "Reds",
      },
    },
    {
      id: "evapotranspiration_8day",
      texts: "Evapotranspiration",
      region: "us-east-1",
      satellite: "modis16a2_myd",
      params: [
        ["bands", "ET_500m"],
        ["unscale", "True"],
        ["resampling", "bilinear"],
        ["padding", 1],
      ],
      colorMap: {
        legendTitle: "8-day evapotranspiration (kg/m^2)",
        min: 0,
        max: 10,
        colorMapName: "Blues",
      },
    },
    {
      id: "rainfall_daily",
      texts: "Precipitation (Daily)",
      region: "us-east-1",
      satellite: "chirps_daily_p05",
      params: [
        ["bands", "precipitation"],
        ["resampling", "bilinear"],
        ["reproject", "bilinear"],
      ],
      colorMap: {
        legendTitle: "Rainfall (mm)",
        min: 0,
        max: 20,
        colorMapName: "Blues",
      },
    },
    {
      id: "rainfall_monthly",
      texts: "Precipitation (Monthly)",
      region: "us-east-1",
      satellite: "chirps_monthly",
      params: [
        ["bands", "precipitation"],
        ["resampling", "bilinear"],
        ["reproject", "bilinear"],
      ],
      colorMap: {
        legendTitle: "Monthly rainfall (mm)",
        min: 0,
        max: 60,
        colorMapName: "Blues",
      },
    },
  ];

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    localStorage.setItem("country", selectedCountry);
  };

  const navigation = [
    {
      label: "Country",
      options: Object.keys(CLIENTS).reduce(function (filtered, key) {
        if (groups?.includes("admin") || groups?.includes(key))
          filtered[key] = CLIENTS[key]["name"];
        return filtered;
      }, {}),
      value: country,
      onChange: handleCountryChange,
    },
    {
      label: "Crop",
      options: {},
      value: crop,
      onChange: setCrop,
    },
    {
      label: "Agricultural Season",
      options: {
        2022: "2022-2023 (Current)",
        2021: "2021-2022",
        2020: "2020-2021",
      },
      value: season,
      onChange: setSeason,
    },
  ];

  const gTAMaxLevel = countryBoundary?.properties?.["gta:max_level"] || 0;

  const setRegionByLevel = (r, level) => {
    const newRegions = [...regions];
    newRegions.splice(Math.min(level, gTAMaxLevel));
    newRegions[level === gTAMaxLevel ? level - 1 : level] = r;
    setRegions(newRegions);
  };
  const getRegionByLevel = (level) => {
    if (level < 0) {
      return regions[0];
    } else if (regions.length > level) {
      return regions[level];
    } else {
      return "All";
    }
  };
  const getRegionNameByLevel = (regionOptions, level) => {
    let options = regionOptions;
    for (let l = 0; l <= level; l++) {
      let regionId = getRegionByLevel(l);
      options = options.children.find((o) => o.id === regionId);
    }
    return options?.name;
  };
  const maxLevel =
    regions.findIndex((x) => x === "All") === -1
      ? Math.min(regions.length, gTAMaxLevel)
      : regions.findIndex((x) => x === "All");
  const nextLevel = Math.min(maxLevel + 1, gTAMaxLevel);
  const getLastRegion = () => {
    if (maxLevel === 0) {
      return regionOptions?.id;
    } else {
      return getRegionByLevel(maxLevel - 1);
    }
  };
  useEffect(() => {
    setRegions(["All"]);
    setCrop("All");
    setActiveSatelliteLayer(null);
  }, [country]);

  useEffect(() => {
    fetchGroups(setGroups);
  }, []);

  useEffect(() => {
    if (!countryStored) {
      navigate("/settings");
    }
  }, [countryStored, navigate]);

  useEffect(() => {
    if (countryStored) {
      setCountry(countryStored);
    } else {
      Hub.listen("auth", (data) => {
        if (data?.payload?.event === "signedIn") {
          fetchGroups(setGroups);
        }
      });
    }
  }, [navigate]);

  return (
    <div>
      <UserContext.Provider
        value={{
          country,
          regions,
          setRegions,
          getRegionByLevel,
          getRegionNameByLevel,
          getLastRegion,
          maxLevel,
          gTAMaxLevel,
          nextLevel,
          crop,
          showFieldDelineation,
          setShowFieldDelineation,
          downloadPoints,
          setDownloadPoints,
          downloadPolygons,
          setDownloadPolygons,
          debugMode,
          setDebugMode,
          activeSatelliteLayer,
          setActiveSatelliteLayer,
          satelliteLayers,
          navigation,
          secondaryNavigation,
          mapRef,
          setMapRef,
          setDate,
          date,
          statsOpen,
          setStatsOpen,
          countryBoundary,
          regionOptions,
          datesOptions,
          setDatesOptions,
          datesOptionsPheno,
          setDatesOptionsPheno,
          setRegionByLevel,
          progress,
          statsDate,
          setStatsDate,
          setCrop,
          groups,
          signOut,
        }}
      >
        <Routes>
          {routes.map(({ path, main }) => (
            <Route key={path} path={path} element={main({ navigation })} />
          ))}
        </Routes>
      </UserContext.Provider>
    </div>
  );
}

export default function App() {
  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => <GTA signOut={signOut} />}
    </Authenticator>
  );
}
