export const API_STAGE = "prod";

export const STAC_STAGE = "prod";

export const TITILER_STAGE = "prod";

export const CLIENTS = {
    "senegal": {
        "id": 1,
        "name": "Senegal",
        "countries": [
            "senegal"
        ],
        "statsDate": "2024-04-04",
        "phenologyModelCrops": [
            "rice"
        ]
    },
    "kenya": {
        "id": 2,
        "name": "Kenya",
        "countries": [
            "kenya"
        ],
        "statsDate": "2024-08-05",
        "phenologyModelCrops": [
            "corn"
        ]
    },
    "morocco": {
        "id": 3,
        "name": "Morocco",
        "countries": [
            "morocco"
        ],
        "statsDate": "2023-04-13",
        "phenologyModelCrops": [
            "cereals"
        ]
    },
    "ivory_coast": {
        "id": 4,
        "name": "Ivory Coast",
        "countries": [
            "ivory_coast"
        ],
        "statsDate": "2024-08-05",
        "phenologyModelCrops": [
            "corn"
        ]
    },
    "ghana": {
        "id": 5,
        "name": "Ghana",
        "countries": [
            "ghana"
        ],
        "statsDate": "2024-08-05",
        "phenologyModelCrops": [
            "corn"
        ]
    },
    "nigeria": {
        "id": 6,
        "name": "Nigeria",
        "countries": [
            "nigeria"
        ],
        "statsDate": "2024-08-04",
        "phenologyModelCrops": [
            "sorghum"
        ]
    },
    "rwanda": {
        "id": 7,
        "name": "Rwanda",
        "countries": [
            "rwanda"
        ],
        "statsDate": "2024-08-04",
        "phenologyModelCrops": [
            "rice"
        ]
    }
};

export const PHENOLOGY_DESCRIPTIONS = {
    "corn": {
        "stage1": "Stage 1: Ungerminated",
        "stage2": "Stage 2: Germinated",
        "stage3": "Stage 3: Leaf appearance",
        "stage123": "Stage 1-3: Ungerminated, germinated, and leaf appearance",
        "stage4": "Stage 4: 4 to 5 leaves stage",
        "stage1234": "Stages 1-4: vegetative stage",
        "stage5": "Stage 5: 6 to 10 leaves stage",
        "stage6": "Stage 6: Flowering",
        "stage7": "Stage 7: Grain filling and maturation",
        "stage8": "Stage 8: Harvesting"
    },
    "sorghum": {
        "stage1234": "Stages 1-4: vegetative stage",
        "stage5": "Stage 5: 6 to 10 leaves stage",
        "stage6": "Stage 6: Panicle formation",
        "stage7": "Stage 7: Grain filling and maturation",
        "stage8": "Stage 8: Harvesting",
        "stage9": "Stage 9: Harvested"
    },
    "cereals": {
        "stage1": "Stage 0: Seeding stage",
        "stage2": "Stage 1: Seeding stage",
        "stage3": "Stage 2: Tillering stage",
        "stage4": "Stage 3: Booting stage",
        "stage5": "Stage 4: Anthesis stage",
        "stage6": "Stage 5: Maturity stage"
    },
    "rice": {
        "stage1": "Stage 1: Ungerminated",
        "stage2": "Stage 2: Germinated",
        "stage12": "Stages 1-2: Ungerminated and germinated",
        "stage3": "Stage 3: Seedling",
        "stage123": "Stages 1-3: Ungerminated, germinated and seedling",
        "stage4": "Stage 4: Tillering",
        "stage5": "Stage 5: Reproductive",
        "stage6": "Stage 6: Heading/Ripening",
        "stage56": "Stages 5-6: Reproductive and Heading/Ripening",
        "stage7": "Stage 7: Maturity",
        "stage8": "Stage 8: Harvesting",
        "stage9": "Stage 9: Harvested"
    }
};

export const DENSITY_BUCKETS = [
    "0%",
    "10%",
    "20%",
    "30%",
    "40%",
    "50%",
    "60%",
    "70%",
    "80%",
    "90%"
];

export const SEQUENTIAL_COLORS = [
    "#edf8fb",
    "#b3cde3",
    "#8c96c6",
    "#8856a7",
    "#810f7c",
    "#3D158E"
];

export const SEQUENTIAL_COLORS_10 = [
    "#0891b200",
    "#0891b211",
    "#0891b222",
    "#0891b233",
    "#0891b244",
    "#0891b255",
    "#0891b266",
    "#0891b277",
    "#0891b288",
    "#0891b299",
    "#0891b2aa"
];

export const CROP_COLORS = [
    "#95A5A6",
    "#229954",
    "#ffbf00",
    "#FFFFC5",
    "#D35400",
    "#d592ba",
    "#949400",
    "#724db8",
    "#2266CC",
    "#800000",
    "#469990",
    "#000075",
    "#f58231",
    "#bfef45",
    "#42d4f4",
    "#f032e6",
    "#ffd8b1",
    "#dcbeff"
];

export const CROPS_BY_COUNTRY = {
    "ivory_coast": [
        "All",
        "fallow",
        "rice",
        "corn",
        "cotton",
        "other"
    ],
    "ghana": [
        "All",
        "corn",
        "rice",
        "soybean"
    ],
    "senegal": [
        "All",
        "fallow",
        "rice",
        "vegetable",
        "sorghum",
        "sweetpotato"
    ],
    "kenya": [
        "All",
        "cowpea",
        "rice",
        "corn",
        "wheat",
        "potato"
    ],
    "nigeria": [
        "All",
        "fallow",
        "rice",
        "legume",
        "sorghum"
    ],
    "morocco": [
        "All",
        "fallow",
        "legume",
        "cereals",
        "other",
        "potato",
        "apple",
        "olive"
    ],
    "rwanda": [
        "All",
        "fallow",
        "rice",
        "corn",
        "grain",
        "vegetable",
        "legume"
    ]
};

export const PHENOLOGIES_BY_COUNTRY = {
    "ivory_coast": [
        "stage1234",
        "stage5",
        "stage6",
        "stage7"
    ],
    "senegal": [
        "stage12",
        "stage3",
        "stage4",
        "stage5",
        "stage6",
        "stage7"
    ],
    "kenya": [
        "stage123",
        "stage4",
        "stage5",
        "stage6",
        "stage7"
    ],
    "ghana": [
        "stage4",
        "stage5",
        "stage6",
        "stage7",
        "stage8"
    ],
    "nigeria": [
        "stage1234",
        "stage5",
        "stage6",
        "stage7",
        "stage8"
    ],
    "morocco": [
        "stage1",
        "stage2",
        "stage3",
        "stage4",
        "stage5",
        "stage6"
    ],
    "rwanda": [
        "stage123",
        "stage4",
        "stage56",
        "stage7"
    ]
};

export const CROPS = {
    "other": {
        "id": 1
    },
    "grain": {
        "id": 2
    },
    "legume": {
        "id": 3
    },
    "croptree": {
        "id": 4
    },
    "vegetable": {
        "id": 5
    },
    "fallow": {
        "id": 11
    },
    "preparedland": {
        "id": 12
    },
    "noncroptree": {
        "id": 13
    },
    "pasture": {
        "id": 14
    },
    "harvesting": {
        "id": 15
    },
    "harvested": {
        "id": 16
    },
    "apple": {
        "id": 21
    },
    "banana": {
        "id": 22
    },
    "cashew": {
        "id": 23
    },
    "cocoa": {
        "id": 24
    },
    "coconut": {
        "id": 25
    },
    "coffee": {
        "id": 26
    },
    "hevea": {
        "id": 27
    },
    "mango": {
        "id": 28
    },
    "orange": {
        "id": 29
    },
    "palmoiltree": {
        "id": 30
    },
    "cola": {
        "id": 31
    },
    "plantain": {
        "id": 32
    },
    "shea": {
        "id": 33
    },
    "olive": {
        "id": 34
    },
    "avocado": {
        "id": 35
    },
    "bean": {
        "id": 50
    },
    "bushbean": {
        "id": 51
    },
    "cassava": {
        "id": 52
    },
    "climbingbean": {
        "id": 53
    },
    "cocoyam": {
        "id": 54
    },
    "corn": {
        "id": 55
    },
    "cotton": {
        "id": 56
    },
    "cowpea": {
        "id": 57
    },
    "frenchbean": {
        "id": 58
    },
    "millet": {
        "id": 59
    },
    "okra": {
        "id": 60
    },
    "onion": {
        "id": 61
    },
    "peanut": {
        "id": 62
    },
    "pepper": {
        "id": 63
    },
    "pineapple": {
        "id": 64
    },
    "potato": {
        "id": 65
    },
    "rice": {
        "id": 66
    },
    "sorghum": {
        "id": 67
    },
    "soybean": {
        "id": 68
    },
    "sugarcane": {
        "id": 69
    },
    "sweetpotato": {
        "id": 70
    },
    "tomato": {
        "id": 71
    },
    "wheat": {
        "id": 72
    },
    "yam": {
        "id": 73
    },
    "barley": {
        "id": 74
    },
    "tobacco": {
        "id": 75
    },
    "pea": {
        "id": 76
    },
    "greengram": {
        "id": 77
    },
    "cucumber": {
        "id": 78
    },
    "sesame": {
        "id": 79
    },
    "sunflower": {
        "id": 80
    },
    "cabbage": {
        "id": 81
    },
    "eggplant": {
        "id": 82
    },
    "All": {
        "id": 0
    },
    "cereals": {
        "id": 2
    }
};

export const PHENOLOGIES = {
    "stage1": {
        "id": 1
    },
    "stage2": {
        "id": 2
    },
    "stage3": {
        "id": 3
    },
    "stage4": {
        "id": 4
    },
    "stage5": {
        "id": 5
    },
    "stage6": {
        "id": 6
    },
    "stage7": {
        "id": 7
    },
    "stage8": {
        "id": 8
    },
    "stage9": {
        "id": 9
    },
    "stage12": {
        "id": 10
    },
    "stage123": {
        "id": 11
    },
    "stage1234": {
        "id": 12
    },
    "stage12345": {
        "id": 13
    },
    "stage123456": {
        "id": 14
    },
    "stage1234567": {
        "id": 15
    },
    "stage12345678": {
        "id": 16
    },
    "stage23": {
        "id": 20
    },
    "stage234": {
        "id": 21
    },
    "stage2345": {
        "id": 22
    },
    "stage23456": {
        "id": 23
    },
    "stage234567": {
        "id": 24
    },
    "stage2345678": {
        "id": 25
    },
    "stage23456789": {
        "id": 26
    },
    "stage34": {
        "id": 30
    },
    "stage345": {
        "id": 31
    },
    "stage3456": {
        "id": 32
    },
    "stage34567": {
        "id": 33
    },
    "stage345678": {
        "id": 34
    },
    "stage3456789": {
        "id": 35
    },
    "stage45": {
        "id": 40
    },
    "stage456": {
        "id": 41
    },
    "stage4567": {
        "id": 42
    },
    "stage45678": {
        "id": 43
    },
    "stage456789": {
        "id": 44
    },
    "stage56": {
        "id": 50
    },
    "stage567": {
        "id": 51
    },
    "stage5678": {
        "id": 52
    },
    "stage56789": {
        "id": 53
    },
    "stage67": {
        "id": 60
    },
    "stage678": {
        "id": 61
    },
    "stage6789": {
        "id": 62
    },
    "stage78": {
        "id": 70
    },
    "stage789": {
        "id": 71
    },
    "stage89": {
        "id": 80
    }
};

export const LANDCOVER_LABELS = {
    "field": {
        "id": 0
    },
    "non_field": {
        "id": 1
    },
    "tree": {
        "id": 2
    },
    "non_tree": {
        "id": 3
    },
    "built": {
        "id": 4
    },
    "non_built": {
        "id": 5
    },
    "water": {
        "id": 6
    },
    "non_water": {
        "id": 7
    }
};

export const COUNTRY_BBOXES = {
    "benin": [
        0.782774245641065,
        6.215779466588288,
        3.848807354045505,
        12.41720486062502
    ],
    "ghana": [
        -3.255420000001322,
        4.736720000000787,
        1.191779999999198,
        11.173299999994924
    ],
    "ivory_coast": [
        -8.618719847999955,
        4.34406159100007,
        -2.506328083999961,
        10.726478170000064
    ],
    "kenya": [
        33.91181945760195,
        -4.702209472788866,
        41.90625762938612,
        5.430648327058691
    ],
    "mali": [
        -12.239236905999954,
        10.141371344000014,
        4.244670359000091,
        24.999511983000012
    ],
    "morocco": [
        -17.104826199999973,
        20.769794499999964,
        -0.998429,
        35.922312
    ],
    "nigeria": [
        2.692612538038247,
        4.270203986225056,
        14.677967754208566,
        13.885713987110876
    ],
    "rwanda": [
        28.8623634543884,
        -2.838980400048399,
        30.89867137776872,
        -1.047408299933068
    ],
    "senegal": [
        -17.530878969999947,
        12.307766467000022,
        -11.348089117999962,
        16.69323770400007
    ],
    "togo": [
        -0.143739,
        6.112430099999882,
        1.8025,
        11.139497
    ]
};

