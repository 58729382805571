import { Link } from "react-router-dom";
import { GlobeEuropeAfricaIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../images/logo.png";

export default function Logo() {
  return (
    <Link
      className="text-2xl font-semibold text-cyan-700 flex justify-center items-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
      to="/"
    >
      <GlobeEuropeAfricaIcon className="mr-2 flex-shrink-0 h-8 w-8" />
      <div className="flex flex-col justify-center -mt-2">
        <div className="flex flex-row">
          <span className="">SIG</span>
        </div>
        <div className="flex space-x-1 h-2 text-xs text-gray-500 -mt-1 font-normal italic">
          <span>Powered by</span>
          <span className="text-amber-500 font-bold drop-shadow-lg">
            Ground Truth Analytics
          </span>
        </div>
      </div>
    </Link>
  );
}
