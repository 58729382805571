import { Link } from "react-router-dom";
import Logo from "./Logo";

export default function NotAuthorized({ signOut }) {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <Logo />
                </div>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <label className="text-center block text-sm font-medium leading-6 text-gray-900">
                        You do not have permission to view any countries. Please
                        contact administrator for access.
                    </label>
                </div>
            </div>

            <div className="mt-6 flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-cyan-800">
                <Link to="#" onClick={signOut}>
                    Logout
                </Link>
            </div>
        </div>
    );
}
