import { Fragment } from "react";
import { Transition } from "@headlessui/react";

export default function Progress({ progress }) {
  const show = progress < 1;
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full absolute bottom-8">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ml-auto mr-14">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="w-0 flex-1 flex justify-between">
                    <div
                      style={{ borderTopColor: "transparent" }}
                      className="w-6 h-6 border-4 border-cyan-600 border-solid rounded-full animate-spin"
                    ></div>
                    <p className="ml-2 w-0 flex-1 text-sm font-medium text-gray-900 self-center">
                      Loading data: {(progress * 100).toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
