import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const Barchart = ({ data, title, xaxis }) => {
  return (
    <div
      className="mx-auto mt-6"
      style={{ maxWidth: "450px", height: "250px" }}
    >
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "category",
              display: true,
              title: {
                display: true,
                text: xaxis,
              },
              // ticks: {
              //   // Include a dollar sign in the ticks
              //   callback: function (value, index, ticks) {
              //     return (value / 10000).toFixed(2);
              //   },
              // },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: title,
            },
          },
        }}
        data={data}
      />
    </div>
  );
};
