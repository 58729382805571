export default function Select({ label, options, onChange, value }) {
  options = options == null ? [] : options;
  const texts = Array.isArray(options) ? options : Object.values(options);
  const values = Array.isArray(options) ? options : Object.keys(options);
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-100"
      >
        {label}
      </label>
      <select
        id="location"
        name="location"
        className="mt-2 block w-full pl-3 pr-10 py-2 text-base text-gray-100 bg-gray-900 border-gray-500 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {options &&
          values.map((v, i) => (
            <option value={v} key={v}>
              {texts[i]}
            </option>
          ))}
      </select>
    </div>
  );
}
