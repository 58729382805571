import { Fragment } from "react";
import "chartjs-adapter-moment";
import Table from "../components/Table.js";
import { Barchart } from "../components/Barchart.js";
import { CLIENTS } from "../config/constants.js";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LogarithmicScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default function Statistics({
  country,
  crop,
  statsDate,
  statsOpen,
  regions,
  getRegionByLevel,
  crops,
  currentTab,
  setCurrentTab,
  showFieldDelineation,
  showPhenologicalStage,
  data,
  dataByCropForTable,
  dataByPhenologyForTable,
  dataForTable,
  bardata,
  bardataCrop,
  bardataPheno,
  bardataNDVI,
  bardataWeather,
  totalArea,
  parcelCount,
  DateRangeToggle,
}) {
  function setCurrent(name) {
    setCurrentTab(name);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    { name: "Land use" },
    { name: "Crop type" },
    { name: "Phenological stage" },
    { name: "Weather and Indices" },
    { name: "Yield" },
  ];

  return (
    <div className="mt-4 bg-white overflow-scroll shadow rounded-lg divide-y divide-gray-200 col-span-4">
      <div className="px-2 py-2 sm:px-6">
        <div className="border-b border-gray-200 pb-5 sm:pb-0">
          <div className="mt-3 sm:mt-4">
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="current-tab"
                name="current-tab"
                onChange={(e) => setCurrent(e.target.value)}
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                defaultValue={tabs[0].name}
              >
                {tabs.map((tab) => {
                  if (
                    !showPhenologicalStage &&
                    tab.name === "Phenological stage"
                  ) {
                    return <Fragment key={tab.name}></Fragment>;
                  } else if (
                    showPhenologicalStage &&
                    tab.name === "Crop type"
                  ) {
                    return <Fragment key={tab.name}></Fragment>;
                  }
                  return <option key={tab.name}>{tab.name}</option>;
                })}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => {
                  if (
                    !showPhenologicalStage &&
                    tab.name === "Phenological stage"
                  ) {
                    return <Fragment key={tab.name}></Fragment>;
                  } else if (
                    showPhenologicalStage &&
                    tab.name === "Crop type"
                  ) {
                    return <Fragment key={tab.name}></Fragment>;
                  }
                  return (
                    <button
                      key={tab.name}
                      onClick={() => setCurrent(tab.name)}
                      className={classNames(
                        tab.name === currentTab
                          ? "border-cyan-500 text-cyan-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium",
                      )}
                      aria-current={
                        tab.name === currentTab ? "page" : undefined
                      }
                    >
                      {tab.name}
                    </button>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
        <div className="mt-5 border-gray-200 grid grid-cols-1">
          <div className="px-2 divide-y divide-gray-200">
            {currentTab === "Land use" && (
              <>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Total area (Ha)
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0">
                    <span className="flex-grow">
                      {totalArea.toLocaleString()}
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Number of parcels
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0">
                    <span className="flex-grow">
                      {parcelCount.toLocaleString()}
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Average parcel size (Ha)
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0">
                    <span className="flex-grow">
                      {parcelCount === 0
                        ? "-"
                        : (totalArea / parcelCount).toLocaleString()}
                    </span>
                  </dd>
                </div>
              </>
            )}
            {Object.keys(bardata).length > 0 && currentTab === "Land use" && (
              <Barchart
                data={bardata}
                title="Number of parcels by size"
                xaxis="Size of parcel (Ha)"
              />
            )}
            {Object.keys(bardataCrop).length > 0 &&
              currentTab === "Crop type" && (
                <Barchart
                  data={bardataCrop}
                  title="Number of parcels by crop type"
                  xaxis="Crop type"
                />
              )}
            {currentTab === "Phenological stage" &&
              (CLIENTS[country]["phenologyModelCrops"].includes(crop) &&
              Object.keys(bardataPheno).length > 0 ? (
                <Barchart
                  data={bardataPheno}
                  title="Number of parcels by phenology"
                  xaxis="Phenological Stage"
                />
              ) : (
                <div className="my-4 text-center text-gray-500 text-sm">
                  <h2 className="mt-6 font-semibold">
                    No phenological stage model for this crop available
                  </h2>
                </div>
              ))}
            {currentTab === "Land use" && (
              <Table
                title={"Regional breakdown"}
                info={dataForTable}
                header={[
                  "Region",
                  "Total parcel counts",
                  "Total Area (Ha)",
                  "Mean Area (Ha)",
                ]}
              />
            )}
            {currentTab === "Crop type" && (
              <Table
                title={"Regional breakdown By Crop"}
                info={dataByCropForTable}
                header={[
                  "Region",
                  "Crop",
                  "Total parcel counts",
                  "Total Area (Ha)",
                  "Mean Area (Ha)",
                ]}
              />
            )}
            {CLIENTS[country]["phenologyModelCrops"].includes(crop) &&
              currentTab === "Phenological stage" && (
                <Table
                  title={"Regional breakdown by phenology"}
                  info={dataByPhenologyForTable}
                  header={[
                    "Region",
                    "Phenology",
                    "Total parcel counts",
                    "Total Area (Ha)",
                    "Mean Area (Ha)",
                  ]}
                />
              )}
            {bardataWeather["datasets"].length > 0 &&
              currentTab === "Weather and Indices" && (
                <>
                  <div>
                    <DateRangeToggle />
                  </div>
                  {bardataWeather.length === 0 ? (
                    <div className="mt-4 text-center text-gray-500 text-sm">
                      <h2 className="mt-6 font-semibold">No Data Available</h2>
                      <p>
                        Please select a different date range or check back
                        later.
                      </p>
                    </div>
                  ) : (
                    <>
                      <div
                        className="mx-auto mt-6"
                        style={{ maxWidth: "450px", height: "250px" }}
                      >
                        <Chart
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                              x: {
                                type: "time",
                                time: {
                                  unit: "day",
                                },
                              },
                              y: {
                                type: "linear",
                                display: true,
                                position: "left",
                              },
                              y1: {
                                type: "linear",
                                display: true,
                                position: "right",
                                grid: {
                                  drawOnChartArea: false,
                                },
                              },
                            },
                            plugins: {
                              legend: {
                                display: true,
                              },
                              title: {
                                display: true,
                                text: "Temperature",
                              },
                            },
                          }}
                          data={bardataWeather}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            {bardataNDVI["datasets"].length > 0 &&
              currentTab === "Weather and Indices" && (
                <div
                  className="mx-auto mt-6"
                  style={{ maxWidth: "450px", height: "250px" }}
                >
                  <Line
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          type: "time",
                          time: {
                            unit: "day",
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: true,
                          text: "NDVI",
                        },
                      },
                    }}
                    data={bardataNDVI}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
