import React, { useContext } from "react";
import "./Layout.css";

import Header from "../Header/Header";
import Progress from "../Progress/Progress";
import { UserContext } from "../../App";
import Settings from "../../pages/Settings";

// Pass the child props
export default function Layout({ children }) {
    const { progress, country, groups, signOut } = useContext(UserContext);

    return (
        <div className="min-h-full">
            {groups?.includes("admin") || groups?.includes(country) ? (
                <>
                    <div className="flex flex-col flex-1">
                        <Header />
                        <main className="flex-1">{children}</main>
                    </div>
                    <Progress progress={progress} />
                </>
            ) : (
                <Settings />
            )}
        </div>
    );
}
