export default function Radio({
  texts,
  comments,
  checked,
  onChange,
  dark = false,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={texts}
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          checked={checked ? "checked" : ""}
          onChange={(event) => onChange(event.target.checked)}
          className="h-4 w-4 rounded border-gray-300 text-gray-400 focus:ring-cyan-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label
          htmlFor="comments"
          className={classNames(
            "font-medium",
            dark ? "text-gray-900" : "text-gray-100",
          )}
        >
          {texts}
        </label>
        <p id="comments-description" className="text-gray-300">
          {comments}
        </p>
      </div>
    </div>
  );
}
